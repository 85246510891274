import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { environment } from '../../../environments/environment';
import { AblioRequest } from '../../models/AblioRequest';

@Injectable({
  providedIn: 'root'
})
export class AblioRequestService {
  ablioRequestChange: Subject<AblioRequest> = new BehaviorSubject<AblioRequest>(null);
  private ablioRequest: AblioRequest;

  constructor(private http: HttpClient) {
  }

  public getAblioRequest(): AblioRequest {
    return this.ablioRequest;
  }

  public getById(id: string, callback: Function) {
    const URI = `${environment.apiUrl}/abliorequest/${id}`;
    return this.http.get(URI).subscribe((data: AblioRequest) => {
        this.ablioRequest = data;
        this.ablioRequestChange.next(this.ablioRequest);
        callback(null, data);
      },
      (err) => callback(err)
    );
  }

  public engageInterpreter(requestId: string, interpreterId: string, callback: Function) {
    const URI = `${environment.apiUrl}/abliorequest/engage/${requestId}/${interpreterId}`;
    return this.http.get(URI).subscribe((response: any) => {
        callback(null, response);
      },
      (err) => callback(err)
    );
  }

  public getAblioInterpreterCancelFee(eventId: string, interpreterAblioId: string, callback: Function) {
    const URI = `${environment.apiUrl}/abliorequest/cancel-fee/${eventId}/${interpreterAblioId}`;
    return this.http.get(URI).subscribe((response: any) => {
        callback(null, response);
      },
      (err) => callback(err)
    );
  }

  public cancelInterpreter(eventId: string, interpreterAblioId: string, callback: Function) {
    const URI = `${environment.apiUrl}/abliorequest/cancel-interpreter/${eventId}/${interpreterAblioId}`;
    return this.http.get(URI).subscribe((response: any) => {
        callback(null, response);
      },
      (err) => callback(err)
    );
  }

  public redoPreviousRequest(requestId: string, callback: Function) {
    const URI = `${environment.apiUrl}/abliorequest/redo/${requestId}`;
    return this.http.post(URI, {}).subscribe((response: any) => {
        callback(null, response);
      },
      (err) => callback(err)
    );
  }

  public getAllAblioRequests(callback: Function) {
    const URI = `${environment.apiUrl}/abliorequest/abliorequests/list`;
    return this.http.get(URI).subscribe((data: AblioRequest[]) => {
      callback(null, data);
    },
      (err) => callback(err)
    );
  }

  public getStats(callback: Function) {
    const URI = `${environment.apiUrl}/abliorequest/abliorequests/stats`;
    return this.http.get(URI).subscribe((data: any) => {
      callback(null, data);
    },
      (err) => callback(err)
    );
  }
}
